<template>
  <div>
    <header-page :title="'Customers'" :hasBackLink="false"/>
  </div>
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue'
export default {
  name: 'Customers',
  components: { HeaderPage }
}
</script>
